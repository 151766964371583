import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import ConsentPage from "./ConsentPage";
import actions from "./actions";
import { withRouter } from "react-router-dom";

const ConsentRoute = ({
  consentGiven,
  path,
  component,
  giveConsent,
  history,
}) =>
  consentGiven ? (
    <Route path={path} component={component} />
  ) : (
    <Route
      path={path}
      render={() => (
        <ConsentPage
          giveConsent={giveConsent}
          denyConsent={() => history.push("/")}
        />
      )}
    />
  );

const mapStateToProps = (state, ownProps) => {
  const cState = state.consent;
  return {
    ...ownProps,
    consentGiven: cState && cState.get("consentGiven"),
  };
};

export default withRouter(connect(mapStateToProps, actions)(ConsentRoute));
