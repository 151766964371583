import authApi from './api/authApi'
import Cookies from 'cookies-js'

export default () => next => action => refreshSessionIfNeeded().then(() => next(action))

async function refreshSessionIfNeeded() {
	const expiry = Cookies.get('session_expiration')
	if (!expiry)
		return
	try {
		const expiresInMin = (new Date(expiry) - new Date()) / 60000

		if (expiresInMin > 0 && expiresInMin < 10) {
			return authApi.refreshToken()
		}
	} catch (e) {
		return authApi.refreshToken()
	}
}