import React from "react";
import querystring from "querystring";
import { Redirect, withRouter } from "react-router-dom";

const LegacyRedirect = ({ match }) => {
  const { url, params, path } = match;
  const qs = {};
  switch (
    path
      .split("/")
      .filter((a) => a && a.length > 0)[0]
      .toLowerCase()
  ) {
    case "authors":
      qs.authors = params.author;
      break;
    case "tags":
      qs.tags = params.tag;
  }
  let redirectUrl = "/reader";
  if (Object.keys(qs).length > 0)
    redirectUrl = `${redirectUrl}?${querystring.encode(qs)}`;
  return <Redirect to={redirectUrl} from={url} />;
};

export default withRouter(LegacyRedirect);
