import React, { useState } from "react";
import classNames from "classnames";
import Link from "../shared/Link";
import "./AppFooter.css";
import { withRouter } from "react-router-dom";
import { FaBars } from "react-icons/fa";

const AppFooter = ({ location }) => {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  const toggleOpen = () => setOpen(!open);

  return (
    <div className={classNames("dbottomnav", { open: open })} id="dmyBottomnav">
      <div className={"dmain"}>
        <button className="menuExpander" onClick={toggleOpen}>
          <FaBars />
        </button>
        <div className={classNames("menuItems", { open: open })}>
          <Link to={"/privacy"} className={"dmenuItem"} onLinkClick={close}>
            Privacy Policy
          </Link>
          <Link to={"/TOS"} className={"dmenuItem"} onLinkClick={close}>
            TOS
          </Link>
          <Link to={"/DMCA"} className={"dmenuItem"} onLinkClick={close}>
            DMCA
          </Link>
          <Link to={"/rules"} className={"dmenuItem"} onLinkClick={close}>
            Rules
          </Link>
          <Link to={"/resources"} className={"dmenuItem"} onLinkClick={close}>
            Resources
          </Link>
          <Link to={"/Credits"} className={"dmenuItem"} onLinkClick={close}>
            Credits
          </Link>
          <Link to={"/changelog"} className={"dmenuItem"} onLinkClick={close}>
            Changelog
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AppFooter);
