export const actionTypes = {
  SAVE_POST: "@@postMgmt:SAVE_POST",
  START_SAVING: "@@postMgmt:START_SAVING",
  SAVE_SUCCESS: "@@postMgmt:SAVE_SUCCESS",
  SAVE_ERROR: "@@postMgmt:SAVE_ERROR",
  DELETE_POST: "@@postMgmt:DELETE_POST",
  DELETE_SUCCESS: "@@postMgmt:DELETE_SUCCESS",
  DELETE_ERROR: "@@postMgmt:DELETE_ERROR",
};

const savePost = (post, push) => ({
  type: actionTypes.SAVE_POST,
  post,
  push,
});

const startSaving = () => ({
  type: actionTypes.START_SAVING,
});

const saveSuccess = () => ({
  type: actionTypes.SAVE_SUCCESS,
});

const saveError = (error) => ({
  type: actionTypes.SAVE_ERROR,
  error,
});

const deletePost = (postId, push) => ({
  type: actionTypes.DELETE_POST,
  postId,
  push,
});

const deleteSuccess = () => ({
  type: actionTypes.DELETE_SUCCESS,
});

const deleteError = (error) => ({
  type: actionTypes.DELETE_ERROR,
  error,
});

export default {
  savePost,
  startSaving,
  saveSuccess,
  saveError,
  deletePost,
  deleteSuccess,
  deleteError,
};
