export const actionTypes = {
  START_LOGIN: "@@auth:START_LOGIN",
  LOGIN: "@@auth:LOGIN",
  LOGIN_ERROR: "@@auth:LOGIN_ERROR",
  SET_LOGIN_ERROR: "@@auth:SET_LOGIN_ERROR",
  LOGIN_SUCCESS: "@@auth:LOGIN_SUCCESS",
  LOGOUT: "@@auth:LOGOUT",
  LOGOUT_SUCCESS: "@@auth:LOGOUT_SUCCESS",
  REGISTER: "@@auth:REGISTER",
};

const startLogin = () => ({
  type: actionTypes.START_LOGIN,
});

const login = (username, password, redirectTo, push, rememberMe) => ({
  type: actionTypes.LOGIN,
  username,
  password,
  redirectTo,
  push,
  rememberMe,
});

const register = (username, password, push) => ({
  type: actionTypes.REGISTER,
  username,
  password,
  push,
});

const logout = () => ({
  type: actionTypes.LOGOUT,
});

const loginError = (error) => ({
  type: actionTypes.LOGIN_ERROR,
  error,
});

const setLoginError = (error) => ({
  type: actionTypes.SET_LOGIN_ERROR,
  error,
});

const loginSuccess = (username) => ({
  type: actionTypes.LOGIN_SUCCESS,
  username,
});
const logoutSuccess = () => ({
  type: actionTypes.LOGOUT_SUCCESS,
});

export default {
  login,
  startLogin,
  loginError,
  loginSuccess,
  setLoginError,
  logout,
  logoutSuccess,
  register,
};
