import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import registerServiceWorker from './registerServiceWorker'
import { Provider, ReactReduxContext } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ConsentRoute from "./components/shared/ConsentRoute";
import { store, persistor } from "./store";
import { IconContext } from "react-icons/lib";
import { BrowserRouter as Router } from "react-router-dom";
import { unregister } from "./registerServiceWorker";

ReactDOM.render(
  <Provider store={store} context={ReactReduxContext}>
    <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ConsentRoute path={"*"} component={App} />
        </Router>
      </PersistGate>
    </IconContext.Provider>
  </Provider>,
  document.getElementById("root")
);
unregister(); // change to registerServiceWorker(); for offline first capabilitys and change to unregister(); to remove it
