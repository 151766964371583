export const actionTypes = {
  CHANGE_PASSWORD: "@@USR_MGMT:CHANGE_PASSWORD",
  CHANGE_PASSWORD_ERROR: "@@USR_MGMT:CHANGE_PASSWORD_ERROR",
};

const changePassword = (oldPw, newPw, push) => ({
  type: actionTypes.CHANGE_PASSWORD,
  oldPw,
  newPw,
  push,
});

const changePasswordError = (error) => ({
  type: actionTypes.CHANGE_PASSWORD_ERROR,
  error,
});

export default {
  changePassword,
  changePasswordError,
};
