import { actionTypes } from "./actions";
import { fromJS } from "immutable";

const MODULE_NAME = "consent";

const initialState = fromJS({
  consentGiven: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GIVE_CONSENT:
      return state.set("consentGiven", true);
    default:
      return state;
  }
};

export default {
  MODULE_NAME,
  reducer,
};
