import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation LikePostMutation($postId: ID!) {
    likePost(postId: $postId) {
      likes
      id
    }
  }
`;

export default (environment, postId) => {
  const variables = {
    postId,
  };

  commitMutation(environment, {
    mutation,
    variables,
    onError: (err) => console.error(err),
  });
};
