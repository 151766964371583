import React from "react";
import PropTypes from "prop-types";
import Button from "../../Button";

import "./ConsentPage.css";

const ConsentPage = ({ giveConsent }) => (
  <div className={"contentWarning"}>
    <h3 className={"pink"}>Content Warning</h3>
    <p>
      You are about to enter a website that contains content only suitable for
      adults. If you're not an adult please leave the page now.
    </p>
    <div className={"consentButtons"}>
      <Button
        active
        title={"I understand and wish to continue"}
        buttonPressed={giveConsent}
      />
      <a className={"doNotConsentButton"} href={"http://endoftheinternet.com"}>
        I do not wish to continue
      </a>
    </div>
  </div>
);

ConsentPage.propTypes = {
  giveConsent: PropTypes.func.isRequired,
  denyConsent: PropTypes.func,
};

export default ConsentPage;
