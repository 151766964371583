/**
 * @flow
 * @relayHash 7fb47a66c179a7e56a39dbfbe34bee07
 */

/* eslint-disable */

"use strict";

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UnLikePostMutationVariables = {|
  postId: string
|};
export type UnLikePostMutationResponse = {|
  +unLikePost: ?{|
    +likes: ?number,
    +id: string,
  |}
|};
export type UnLikePostMutation = {|
  variables: UnLikePostMutationVariables,
  response: UnLikePostMutationResponse,
|};
*/

/*
mutation UnLikePostMutation(
  $postId: ID!
) {
  unLikePost(postId: $postId) {
    likes
    id
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: "LocalArgument",
        name: "postId",
        type: "ID!",
        defaultValue: null,
      },
    ],
    v1 = [
      {
        kind: "LinkedField",
        alias: null,
        name: "unLikePost",
        storageKey: null,
        args: [
          {
            kind: "Variable",
            name: "postId",
            variableName: "postId",
            type: "ID!",
          },
        ],
        concreteType: "Post",
        plural: false,
        selections: [
          {
            kind: "ScalarField",
            alias: null,
            name: "likes",
            args: null,
            storageKey: null,
          },
          {
            kind: "ScalarField",
            alias: null,
            name: "id",
            args: null,
            storageKey: null,
          },
        ],
      },
    ];
  return {
    kind: "Request",
    fragment: {
      kind: "Fragment",
      name: "UnLikePostMutation",
      type: "Mutation",
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/),
    },
    operation: {
      kind: "Operation",
      name: "UnLikePostMutation",
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/),
    },
    params: {
      operationKind: "mutation",
      name: "UnLikePostMutation",
      id: null,
      text: "mutation UnLikePostMutation(\n  $postId: ID!\n) {\n  unLikePost(postId: $postId) {\n    likes\n    id\n  }\n}\n",
      metadata: {},
    },
  };
})();
// prettier-ignore
(node/*: any*/).hash = '31d2d9e24379531eb09e5875905fd97f';
module.exports = node;
