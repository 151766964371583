import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "./LoginPage.css";

import Button from "../../shared/Button";
import LoadingOverlay from "../../shared/LoadingOverlay";
import actions from "../actions";
import reducer from "../reducer";

const LoginPage = ({
  loggingIn,
  error,
  login,
  location,
  history: { push },
}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    doLogin();
  };

  const doLogin = () =>
    !login
      ? null
      : login(username, password, location.referrer, push, rememberMe);

  const loginEnabled =
    (username || "").length > 0 && (password || "").length > 0;
  const handleKeyDown = (e) =>
    e.keyCode === 13 && loginEnabled ? doLogin() : null;

  return (
    <div className={"loginPage"}>
      {loggingIn ? <LoadingOverlay /> : null}
      <h3 className={"pink"}>Login</h3>
      <form onSubmit={handleSubmit} className={"loginBox"}>
        <div className={"input"}>
          <label htmlFor="username">Username</label>
          <input
            id="username"
            type="text"
            className={"userNameBox"}
            onChange={(evt) => setUsername(evt.target.value)}
            onKeyDown={handleKeyDown}
            autoComplete="username"
          />
        </div>
        <div className={"input"}>
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            onChange={(evt) => setPassword(evt.target.value)}
            onKeyDown={handleKeyDown}
            autoComplete="current-password"
          />
        </div>
        <div className={"checkBox"}>
          <input
            id="rememberMe"
            type="checkbox"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          <label htmlFor="rememberMe">Remember me</label>
        </div>
        {error ? <div className={"loginError"}>{error}</div> : null}
        <Button
          title={"Log in"}
          type="submit"
          disabled={!loginEnabled}
        />
      </form>
      <div className={"spacer"} />
      <div className={"registerBox"}>
        <label>Not registered yet? </label>
        <Link to={"/register"}>
          <Button title={"Register"} />
        </Link>
      </div>
      <br /> <br />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const myState = state[reducer.MODULE_NAME];
  return {
    ...ownProps,
    loggingIn: myState.get("loggingIn", false),
    error: myState.get("loginError"),
  };
};

export default connect(mapStateToProps, actions)(LoginPage);
