import {
	Environment,
	Network,
	RecordSource,
	Store
} from 'relay-runtime'

import config from './config'
import authApi from './api/authApi'
import {store} from './store'
import authActions from './components/AuthControl/actions'
import 'whatwg-fetch'

async function fetchQuery(operation, variables) {
	const headers = {
		'Content-Type': 'application/json'
	}
	try {
		const token = await authApi.getToken()
		if (token && token.length > 0)
			headers.authorization = `Bearer ${token}`
	}
	catch (e) {
		store.dispatch(authActions.logoutSuccess())
	}

	return fetch(config.graphServer, {
		method: 'POST',
		redirect: 'follow',
		headers,
		body: JSON.stringify({
			query: operation.text,
			variables
		})
	}).then(response => {
		return response.json()
	})
}

const environment = new Environment({
	network: Network.create(fetchQuery),
	store: new Store(new RecordSource())
})

export default environment