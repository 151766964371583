import { actionTypes } from "./actions";
import { fromJS } from "immutable";

const MODULE_NAME = "userManagement";

const initialState = fromJS({
  changePwError: "",
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_PASSWORD_ERROR:
      return state.set("changePwError", action.error);
    default:
      return state;
  }
};

export default {
  MODULE_NAME,
  reducer,
};
