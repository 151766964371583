import React from "react";
import { connect } from "react-redux";
import "./AuthControl.css";
import Link from "../shared/Link";
import actions from "./actions";
import reducer from "./reducer";

const AuthControl = ({ loggedInUser, logout, className, onLinkClick }) =>
  loggedInUser ? (
    <Link action={logout} className={className} onLinkClick={onLinkClick}>
      <div className={"loggedInInfo"}>
        <div className={"loggedInUser"}>Hello {loggedInUser}</div>
        Logout
      </div>
    </Link>
  ) : (
    <Link to={"/login"} className={className} onLinkClick={onLinkClick}>
      Login
    </Link>
  );

const mapStateToProps = (state, ownProps) => {
  const myState = state[reducer.MODULE_NAME];
  return {
    ...ownProps,
    loggedInUser: myState.get("loggedInUser"),
  };
};

export default connect(mapStateToProps, actions)(AuthControl);
