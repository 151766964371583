import { fromJS } from "immutable";
import { actionTypes } from "./actions";
import authApi from "../../api/authApi";

const MODULE_NAME = "auth";

const initialState = fromJS({
  loggingIn: false,
  loggedInUser: "",
});

const logoutSucces = (state) => state.set("loggedInUser", undefined);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_LOGIN:
      return state.set("loggingIn", true).delete("loginError");
    case actionTypes.LOGIN_SUCCESS:
      return state
        .set("loggingIn", false)
        .delete("loginError")
        .set("loggedInUser", action.username);
    case actionTypes.SET_LOGIN_ERROR:
      return state.set("loggingIn", false).set("loginError", action.error);
    case actionTypes.LOGOUT_SUCCESS:
      return logoutSucces(state);
    default:
      return authApi.isLoggedIn() ? state : logoutSucces(state);
  }
};

export default { MODULE_NAME, reducer };
