import React from "react";
import { Link as DomLink } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./Link.css";

const renderChildren = (children) =>
  React.isValidElement(children) ? (
    React.cloneElement(children, {
      className: classNames("linkedElement", children.props.className),
    })
  ) : (
    <div className={`linkedElement`}>{children}</div>
  );

const getProps = (props) => ({
  ...props,
  className: classNames("unStyledLink", props.className),
});

const Link = (props) => {
  const children = renderChildren(props.children);
  const preparedProps = getProps(props);

  delete preparedProps.action;
  delete preparedProps.onLinkClick;
  if (props.action)
    return (
      <div
        {...preparedProps}
        onClick={(e) => {
          if (props.onLinkClick) props.onLinkClick();
          props.action(e);
        }}
      >
        {children}
      </div>
    );

  return (
    <DomLink
      {...preparedProps}
      onClick={() => {
        if (props.onLinkClick) props.onLinkClick();
      }}
    >
      {children}
    </DomLink>
  );
};

Link.propTypes = {
  action: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onLinkClick: PropTypes.func,
};

export default Link;
