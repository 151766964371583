import { all, call, put, takeEvery, select } from "redux-saga/effects";
import actions, { actionTypes } from "./actions";

import authApi from "../../api/authApi";

const currentUserSelector = (state) => state.auth.get("loggedInUser");

function* changePassword(params) {
  try {
    yield put(actions.changePasswordError(""));
    const user = yield select(currentUserSelector);
    if (params.oldPw !== params.newPw) {
      yield call(authApi.changePassword, {
        username: user,
        password: params.oldPw,
        newPassword: params.newPw,
      });
    }
    if (params.push) yield call(params.push, "/myAccount");
  } catch (e) {
    yield put(actions.changePasswordError(e));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.CHANGE_PASSWORD, changePassword)]);
}
