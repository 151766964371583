import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const AuthenticatedRoute = ({ authenticated, component, path, location }) =>
  authenticated ? (
    <Route path={path} component={component} />
  ) : (
    <Redirect
      to={{ pathname: "/login", referrer: location.pathname }}
      from={location.pathname}
    />
  );

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    authenticated: (state.auth.get("loggedInUser") || "").length !== 0,
  };
};

export default connect(mapStateToProps)(AuthenticatedRoute);
