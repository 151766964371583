import React from "react";
import "./LoadingOverlay.css";

const LoadingOverlay = () => (
  <div className="overlay">
    <div className="spinner">
      <div className="double-bounce1"></div>
      <div className="double-bounce2"></div>
    </div>
  </div>
);

export default LoadingOverlay;
