export const actionTypes = {
  LIKE_POST: "@@PR:LIKE_POST",
  POST_LIKED: "@@PR:POST_LIKED",
  POST_UN_LIKED: "@@PR:POST_UN_LIKED",
  UNLIKE_POST: "@@PR:UNLIKE_POST",
  FETCH_LIKES: "@@PR:FETCH_LIKES",
  LIKES_FETCHED: "@@PR:LIKES_FETCHED",
  TOGGLE_COMMENTS: "@@PR:TOGGLE_COMMENTS",
  COMMENT_TEXT_CHANGED: "@@PR:COMMENT_TEXT_CHANGED",
  POST_COMMENT: "@@PR:POST_COMMENT",
  CLEAR_NEW_COMMENT_TEXT: "@@PR:CLEAR_NEW_COMMENT_TEXT",
};

const likePost = (postId) => ({
  type: actionTypes.LIKE_POST,
  postId,
});

const unlikePost = (postId) => ({
  type: actionTypes.UNLIKE_POST,
  postId,
});

const likesFetched = (likes) => ({
  type: actionTypes.LIKES_FETCHED,
  likes,
});

const fetchLikes = () => ({
  type: actionTypes.FETCH_LIKES,
});

const toggleComments = (post) => ({
  type: actionTypes.TOGGLE_COMMENTS,
  post,
});

const postComment = (postId, postDate, comment) => ({
  type: actionTypes.POST_COMMENT,
  postId,
  postDate,
  comment,
});

const postLiked = (post) => ({
  type: actionTypes.POST_LIKED,
  post,
});

const postUnLiked = (post) => ({
  type: actionTypes.POST_UN_LIKED,
  post,
});

export default {
  likePost,
  fetchLikes,
  unlikePost,
  likesFetched,
  toggleComments,
  postComment,
  postLiked,
  postUnLiked,
};
