import {applyMiddleware, createStore} from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './saga'
import {persistStore, persistReducer} from 'redux-persist'
import {CookieStorage} from 'redux-persist-cookie-storage'
import Cookies from 'cookies-js'
import immutableTransform from 'redux-persist-transform-immutable'
import sessionMiddleware from './sessionMiddleware'
import createRootReducer from './reducer'

const sagaMiddleware = createSagaMiddleware()

const persistConfig = {
	transforms: [immutableTransform()],
	key: 'root',
	storage: new CookieStorage(Cookies/*, options */)
}

const store = createStore(
	persistReducer(persistConfig, createRootReducer()),
	applyMiddleware(sagaMiddleware, sessionMiddleware)
)

const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export {store, persistor}