/**
 * @flow
 * @relayHash 2c4c72675a2146f28eda54a6f5cecb7e
 */

/* eslint-disable */

"use strict";

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Comment_comment$ref = any;
export type NewComment = {|
  comment: string,
  postId: string,
  postDate: string,
|};
export type NewCommentMutationVariables = {|
  comment: NewComment
|};
export type NewCommentMutationResponse = {|
  +newComment: ?{|
    +newCommentEdge: ?{|
      +node: ?{|
        +$fragmentRefs: Comment_comment$ref
      |}
    |}
  |}
|};
export type NewCommentMutation = {|
  variables: NewCommentMutationVariables,
  response: NewCommentMutationResponse,
|};
*/

/*
mutation NewCommentMutation(
  $comment: NewComment!
) {
  newComment(comment: $comment) {
    newCommentEdge {
      node {
        ...Comment_comment
      }
    }
  }
}

fragment Comment_comment on Comment {
  author {
    name
  }
  date
  comment
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: "LocalArgument",
        name: "comment",
        type: "NewComment!",
        defaultValue: null,
      },
    ],
    v1 = [
      {
        kind: "Variable",
        name: "comment",
        variableName: "comment",
        type: "NewComment!",
      },
    ];
  return {
    kind: "Request",
    fragment: {
      kind: "Fragment",
      name: "NewCommentMutation",
      type: "Mutation",
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: "LinkedField",
          alias: null,
          name: "newComment",
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: "NewCommentResponse",
          plural: false,
          selections: [
            {
              kind: "LinkedField",
              alias: null,
              name: "newCommentEdge",
              storageKey: null,
              args: null,
              concreteType: "CommentEdge",
              plural: false,
              selections: [
                {
                  kind: "LinkedField",
                  alias: null,
                  name: "node",
                  storageKey: null,
                  args: null,
                  concreteType: "Comment",
                  plural: false,
                  selections: [
                    {
                      kind: "FragmentSpread",
                      name: "Comment_comment",
                      args: null,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    operation: {
      kind: "Operation",
      name: "NewCommentMutation",
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: "LinkedField",
          alias: null,
          name: "newComment",
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: "NewCommentResponse",
          plural: false,
          selections: [
            {
              kind: "LinkedField",
              alias: null,
              name: "newCommentEdge",
              storageKey: null,
              args: null,
              concreteType: "CommentEdge",
              plural: false,
              selections: [
                {
                  kind: "LinkedField",
                  alias: null,
                  name: "node",
                  storageKey: null,
                  args: null,
                  concreteType: "Comment",
                  plural: false,
                  selections: [
                    {
                      kind: "LinkedField",
                      alias: null,
                      name: "author",
                      storageKey: null,
                      args: null,
                      concreteType: "User",
                      plural: false,
                      selections: [
                        {
                          kind: "ScalarField",
                          alias: null,
                          name: "name",
                          args: null,
                          storageKey: null,
                        },
                      ],
                    },
                    {
                      kind: "ScalarField",
                      alias: null,
                      name: "date",
                      args: null,
                      storageKey: null,
                    },
                    {
                      kind: "ScalarField",
                      alias: null,
                      name: "comment",
                      args: null,
                      storageKey: null,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    params: {
      operationKind: "mutation",
      name: "NewCommentMutation",
      id: null,
      text: "mutation NewCommentMutation(\n  $comment: NewComment!\n) {\n  newComment(comment: $comment) {\n    newCommentEdge {\n      node {\n        ...Comment_comment\n      }\n    }\n  }\n}\n\nfragment Comment_comment on Comment {\n  author {\n    name\n  }\n  date\n  comment\n}\n",
      metadata: {},
    },
  };
})();
// prettier-ignore
(node/*: any*/).hash = '988c6d41f35ba1eed012f8a3be32a740';
module.exports = node;
