import crypto from "crypto";
import config from "../config";
import { SessionExpiredError } from "../errors";
import Cookies from "cookies-js";
import "whatwg-fetch";

const AUTH_TOKEN_KEY = "token";
const SESSION_EXPIRY = "session_expiration";
const TOKEN_EXPIRY = "token_expiration";

const ALL_SESSION_STORAGE_KEYS = [AUTH_TOKEN_KEY, SESSION_EXPIRY, TOKEN_EXPIRY];

const hashPassword = (plainText) =>
  crypto.createHash("md5").update(plainText).digest("hex");

const getAuthHeader = (options) =>
  `Basic ${new Buffer(
    `${options.username}:${hashPassword(options.password)}`
  ).toString("base64")}`;

async function login(options) {
  const fetchOptions = {
    method: "GET",
    headers: {
      Authorization: getAuthHeader(options),
    },
    credentials: "include",
  };
  return fetch(`${config.authServer}/login`, fetchOptions)
    .catch(() => {
      throw "Authentication service currently unavailable";
    })
    .then(updateSessionData)
    .then((res) => {
      if (res.status === 401) throw "Username or password incorrect";
      if (res.status !== 200) throw res.statusText;
      return res.json();
    })
    .then((tokenObj) => {
      const expiryDate = options.rememberMe
        ? new Date(Date.now() + 30 * 24 * 60 * 60 * 1000) // 30 days
        : new Date(Date.now() + 2 * 60 * 60 * 1000); // 2 hours
      return saveAuthToken(tokenObj, expiryDate);
    });
}

async function refreshToken() {
  const fetchOptions = {
    method: "GET",
    credentials: "include",
  };
  return fetch(`${config.authServer}/token`, fetchOptions)
    .catch(() => {
      throw "Authentication service currently unavailable";
    })
    .then(updateSessionData)
    .then((res) => {
      if (res.status === 401) {
        return logout().then(() => {
          throw new SessionExpiredError();
        });
      }
      if (res.status !== 200) throw res.statusText;
      return res.json();
    })
    .then(saveAuthToken);
}

async function register(options) {
  return fetch(`${config.authServer}/register`, {
    body: JSON.stringify({
      username: options.username,
      password: hashPassword(options.password),
    }),
    headers: {
      "content-type": "application/json",
    },
    method: "POST",
    credentials: "include",
  })
    .catch(() => {
      throw "Registration service currently unavailable";
    })
    .then(updateSessionData)
    .then((res) =>
      res.json().then((json) => ({ status: res.status, body: json }))
    )
    .then((res) => {
      if (res.status === 200) return res.body;
      throw res.body.message;
    })
    .then(saveAuthToken);
}

async function changePassword(options) {
  return fetch(`${config.authServer}/change-password`, {
    body: JSON.stringify({
      newPassword: hashPassword(options.newPassword),
    }),
    headers: {
      "content-type": "application/json",
      Authorization: getAuthHeader(options),
    },
    method: "PUT",
    credentials: "include",
  })
    .catch(() => {
      throw "Registration service currently unavailable";
    })
    .then(updateSessionData)
    .then((res) => {
      if (res.status === 401) throw "Username or password incorrect";
      if (res.status !== 200) throw res.statusText;
      return res.json();
    })
    .then(saveAuthToken);
}

const addMsToDate = (ms, date = new Date()) =>
  new Date(date.getTime() + 120 * 60000);

const updateSessionData = (res) => {
  const expiresHeader = res.headers.get("Expires");
  if (expiresHeader) {
    Cookies.set(SESSION_EXPIRY, addMsToDate(expiresHeader).toISOString());
  }
  return res;
};

const saveAuthToken = (tokenObj, expiryDate) => {
  Cookies.set(AUTH_TOKEN_KEY, tokenObj.token, { expires: expiryDate });
  Cookies.set(TOKEN_EXPIRY, addMsToDate(tokenObj.expiresIn));
  return tokenObj;
};

const isLoggedIn = () =>
  ALL_SESSION_STORAGE_KEYS.reduce(
    (result, key) => result || Cookies.get(key) !== null,
    false
  );

const logout = async () => {
  if (!isLoggedIn()) return;

  ALL_SESSION_STORAGE_KEYS.forEach((key) => Cookies.expire(key));

  return fetch(`${config.authServer}/logout`, {
    method: "GET",
    credentials: "include",
  }).then((res) => console.log("loggedOut", res.status));
};

const getToken = async () => {
  if (!isLoggedIn()) return;

  const expiry = Cookies.get(TOKEN_EXPIRY);
  const storedToken = Cookies.get(AUTH_TOKEN_KEY);

  if (!storedToken || !expiry || new Date(expiry) < new Date())
    return refreshToken().then((tokenObj) => tokenObj.token);

  return storedToken;
};

export default {
  login,
  register,
  logout,
  isLoggedIn,
  getToken,
  changePassword,
  refreshToken,
};
