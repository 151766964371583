import {fork, all} from 'redux-saga/effects'

import postSaga from './components/Post/saga'
import authSaga from './components/AuthControl/saga'
import postMgmtSaga from './components/PostManagement/saga'
import userMgmtSaga from './components/UserPage/saga'

export default function* rootSaga() {
	yield all([
		fork(postSaga),
		fork(authSaga),
		fork(postMgmtSaga),
		fork(userMgmtSaga)
	])
}