import { fromJS } from "immutable";
import { actionTypes } from "./actions";

const MODULE_NAME = "postMgmt";

const initialState = fromJS({
  saving: false,
  savingError: undefined,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_SAVING:
      return state.set("saving", true).set("savingError", undefined);
    case actionTypes.SAVE_ERROR:
      return state.set("savingError", action.error).set("saving", false);
    case actionTypes.SAVE_SUCCESS:
      return state.set("saving", false).set("savingError", undefined);
    default:
      return state;
  }
};

export default { MODULE_NAME, reducer };
