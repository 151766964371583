/**
 * @flow
 * @relayHash 0659ee6a1b0ac87a94729d514f9749ce
 */

/* eslint-disable */

"use strict";

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LikePostMutationVariables = {|
  postId: string
|};
export type LikePostMutationResponse = {|
  +likePost: ?{|
    +likes: ?number,
    +id: string,
  |}
|};
export type LikePostMutation = {|
  variables: LikePostMutationVariables,
  response: LikePostMutationResponse,
|};
*/

/*
mutation LikePostMutation(
  $postId: ID!
) {
  likePost(postId: $postId) {
    likes
    id
  }
}
*/

const node /*: ConcreteRequest*/ = (function () {
  var v0 = [
      {
        kind: "LocalArgument",
        name: "postId",
        type: "ID!",
        defaultValue: null,
      },
    ],
    v1 = [
      {
        kind: "LinkedField",
        alias: null,
        name: "likePost",
        storageKey: null,
        args: [
          {
            kind: "Variable",
            name: "postId",
            variableName: "postId",
            type: "ID!",
          },
        ],
        concreteType: "Post",
        plural: false,
        selections: [
          {
            kind: "ScalarField",
            alias: null,
            name: "likes",
            args: null,
            storageKey: null,
          },
          {
            kind: "ScalarField",
            alias: null,
            name: "id",
            args: null,
            storageKey: null,
          },
        ],
      },
    ];
  return {
    kind: "Request",
    fragment: {
      kind: "Fragment",
      name: "LikePostMutation",
      type: "Mutation",
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/),
    },
    operation: {
      kind: "Operation",
      name: "LikePostMutation",
      argumentDefinitions: (v0 /*: any*/),
      selections: (v1 /*: any*/),
    },
    params: {
      operationKind: "mutation",
      name: "LikePostMutation",
      id: null,
      text: "mutation LikePostMutation(\n  $postId: ID!\n) {\n  likePost(postId: $postId) {\n    likes\n    id\n  }\n}\n",
      metadata: {},
    },
  };
})();
// prettier-ignore
(node/*: any*/).hash = '1325719f32c14bfcd0f51308fda7a498';
module.exports = node;
