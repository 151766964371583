import {combineReducers} from 'redux'
import postReducer from './components/Post/reudcer'
import authReducer from './components/AuthControl/reducer'
import mgmtReducer from './components/PostManagement/reducer'
import consentReducer from './components/shared/ConsentRoute/reudcer'
import userMgmtReducer from './components/UserPage/reudcer'


export default () => combineReducers({
	[postReducer.MODULE_NAME]: postReducer.reducer,
	[authReducer.MODULE_NAME]: authReducer.reducer,
	[mgmtReducer.MODULE_NAME]: mgmtReducer.reducer,
	[consentReducer.MODULE_NAME]: consentReducer.reducer,
	[userMgmtReducer.MODULE_NAME]: userMgmtReducer.reducer
})