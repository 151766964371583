export const actionTypes = {
  GIVE_CONSENT: "@@CONSENT:GIVE_CONSENT",
};

const giveConsent = () => ({
  type: actionTypes.GIVE_CONSENT,
});

export default {
  giveConsent,
};
