import {
  all,
  call,
  delay,
  put,
  putResolve,
  takeEvery,
} from "redux-saga/effects";
import actions, { actionTypes } from "./actions";
import postActions from "../Post/actions";

import authApi from "../../api/authApi";

function* login(params) {
  yield put(actions.startLogin());
  try {
    yield call(authApi.login, {
      username: params.username,
      password: params.password,
      rememberMe: params.rememberMe,
    });
    yield putResolve(actions.loginSuccess(params.username));
    yield call(params.push, params.redirectTo || "/reader");
    yield put(postActions.fetchLikes());
  } catch (e) {
    yield put(actions.loginError(e));
  }
}

function* register(params) {
  yield put(actions.startLogin());
  try {
    yield call(authApi.register, {
      username: params.username,
      password: params.password,
    });
    yield putResolve(actions.loginSuccess(params.username));
    yield call(params.push, "/reader");
  } catch (e) {
    yield put(actions.loginError(e));
  }
}

function* loginError(params) {
  const message = params && params.error ? params.error.toString() : undefined;
  if (message) {
    yield put(actions.setLoginError(params.error.toString()));
    yield delay(10000);
  }
  yield put(actions.setLoginError());
}

function* logout() {
  try {
    yield call(authApi.logout);
    yield put(actions.logoutSuccess());
  } catch (e) {}
}

export default function* rootSaga() {
  yield all([
    takeEvery(actionTypes.LOGIN, login),
    takeEvery(actionTypes.LOGOUT, logout),
    takeEvery(actionTypes.REGISTER, register),
    takeEvery(actionTypes.LOGIN_ERROR, loginError),
  ]);
}
