import React, { useState } from "react";
import classNames from "classnames";

import Link from "../shared/Link";
import AuthControl from "../AuthControl";
import "./AppHeader.css";
import logo from "./logo.png";
import { FaBars } from "react-icons/fa";
import { withRouter } from "react-router-dom";

const AppHeader = ({ location }) => {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  const toggleOpen = () => setOpen(!open);

  return (
    <div className={classNames("topnav", { open: open })} id="myTopnav">
      <div className={"main"}>
        <Link to={"/"} className={"menuItem"} onLinkClick={close}>
          <img
            className={"headerLogo"}
            src="https://opentgc.com/logo.png" /* alt={logo} */
          />
        </Link>
        <div className={"menuExpanderContainer"}>
          <Link
            action={toggleOpen}
            className={"menuExpander"}
            onLinkClick={close}
          >
            <FaBars />
          </Link>
        </div>
      </div>
      <Link to={"/reader"} className={"menuItem"} onLinkClick={close}>
        Reader
      </Link>
      <Link
        to={{
          pathname: "/search",
          prevPath: location.pathname,
          prevSearch: location.search,
        }}
        className={"menuItem mobileOnly"}
        onLinkClick={close}
      >
        Search
      </Link>
      <Link to={"/postmgmt"} className={"menuItem"} onLinkClick={close}>
        Create
      </Link>
      <Link to={"/taglist"} className={"menuItem"} onLinkClick={close}>
        Tags
      </Link>
      <a href="/discord" target={"_blank"} className={"unStyledLink menuItem"}>
        Discord
      </a>
      {/* <Link to={"/discord"} className={"menuItem"} onLinkClick={close}>
        Discord
      </Link> */}
      {/* <a
        href="https://www.soulgen.net/ai-chat/?utm_source=ref-optgc&cp_id=tab"
        target={"_blank"}
        className={"unStyledLink menuItem"}
      >
        AI Sexting
      </a> */}
      {/* 	  <Link to={"/img"} className={"menuItem"} onLinkClick={close}>
        ImgUploader
      </Link> */}
      <div className={"spacer"} />
      <Link to={"/myAccount"} className={"menuItem"} onLinkClick={close}>
        Profile
      </Link>
      <AuthControl className={"menuItem"} onLinkClick={close} />
    </div>
  );
};

export default withRouter(AppHeader);
