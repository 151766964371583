import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation NewCommentMutation($comment: NewComment!) {
    newComment(comment: $comment) {
      newCommentEdge {
        node {
          ...Comment_comment
        }
      }
    }
  }
`;

export default (environment, comment, username) => {
  const variables = {
    comment,
  };

  const optimisticResponse = {
    newComment: {
      newCommentEdge: {
        node: {
          date: new Date(),
          comment: comment.comment,
          author: {
            name: username || "Me",
          },
        },
      },
    },
  };

  commitMutation(environment, {
    mutation,
    variables,
    optimisticResponse,
    // onCompleted: (response, errors) => {
    // },
    onError: (err) => console.error(err),
    configs: [
      {
        type: "RANGE_ADD",
        parentID: comment.postId,
        connectionInfo: [
          {
            key: "Post_comments",
            rangeBehavior: "append",
          },
        ],
        edgeName: "newCommentEdge",
      },
    ],
  });
};
