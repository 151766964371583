import { actionTypes } from "./actions";
import { fromJS } from "immutable";

const MODULE_NAME = "post";

const initialState = fromJS({
  postData: {
    pageCount: 1,
    currentPage: 0,
    pageSize: 5,
    posts: [],
  },
  likes: [],
  showComments: [],
  newCommentTexts: {},
});

function toggleComments(state, action) {
  const showComments = state.get("showComments").toJS();
  if (showComments.includes(action.post))
    return state.set(
      "showComments",
      fromJS(showComments.filter((a) => a !== action.post))
    );

  showComments.push(action.post);
  return state.set("showComments", fromJS(showComments));
}

function postLiked(state, action) {
  const postId = action.post;

  const storedLikes = state.get("likes");
  let likes = storedLikes ? storedLikes.toJS() : [];
  likes.push(postId);

  return state.set("likes", fromJS(likes));
}

function postUnLiked(state, action) {
  const postId = action.post;

  const storedLikes = state.get("likes");
  if (storedLikes) {
    let likes = storedLikes.toJS().filter((like) => like !== postId);
    return state.set("likes", fromJS(likes));
  }
  return state;
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIKES_FETCHED:
      return state.set("likes", fromJS(action.likes));
    case actionTypes.TOGGLE_COMMENTS:
      return toggleComments(state, action);
    case actionTypes.POST_LIKED:
      return postLiked(state, action);
    case actionTypes.POST_UN_LIKED:
      return postUnLiked(state, action);
    default:
      return state;
  }
};

export default {
  MODULE_NAME,
  reducer,
};
