import { all, call, put, putResolve, takeEvery } from "redux-saga/effects";
import actions, { actionTypes } from "./actions";
import api from "../../api/postEditApi";
import authActions from "../AuthControl/actions";

const cleanTitle = (text) => text.replace(/([^a-zA-Z0-9 !?.,'])/g, "");
const cleanText = (text) => text.replace(/\n{4,}/g, "\n\n\n");
const cleanTags = (text) => text.replace(/([^a-zA-Z0-9\-_,])/g, "");

const decodeId = (id) => atob(id).split(":")[1];

function* savePost(args) {
  yield put(actions.startSaving());
  try {
    const post = args.post;

    post.title = cleanTitle(post.title);
    post.text = cleanText(post.text);
    post.tags = cleanTags(post.tags);

    if (!post.id) {
      yield call(api.savePost, post);
      yield put(actions.saveSuccess());
      yield call(args.push, "/posts");
    } else {
      yield call(api.savePost, post);
      yield put(actions.saveSuccess());
      yield call(args.push, `/post/${post.id}`);
    }
  } catch (e) {
    if (e.status === 401) {
      yield put(
        actions.saveError(
          `Post could not be saved. It seems like your session expired. Please try again`
        )
      );
      yield put(authActions.logout());
    } else
      yield put(
        actions.saveError(
          `Post could not be saved${e.text ? `: '${e.text}'` : ""}`
        )
      );
  }
}

function* deletePost(args) {
  try {
    console.log("Saga deletePost called, postId:", args.postId);
    const decodedPostId = decodeId(args.postId);
    yield call(api.deletePost, decodedPostId);
    yield put(actions.deleteSuccess());
    yield call(args.push, "/posts");
  } catch (e) {
    console.error("Error in deletePost saga:", e);
    yield put(
      actions.deleteError(
        `Post could not be deleted${e.text ? `: '${e.text}'` : ""}`
      )
    );
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actionTypes.SAVE_POST, savePost),
    takeEvery(actionTypes.DELETE_POST, deletePost),
  ]);
}
