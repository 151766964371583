import config from "../config";
import Cookies from "cookies-js";
import "whatwg-fetch";

function savePost(post) {
  return fetch(`${config.postCreator}/save`, {
    body: JSON.stringify(post),
    headers: {
      authorization: `Bearer ${Cookies.get("token")}`,
      "content-type": "application/json",
    },
    method: "POST",
    mode: "cors",
  })
    .catch(() => {
      throw { text: "Post could not be saved" };
    })
    .then((res) => {
      if (res.status === 401)
        return {
          status: res.status,
          statusText: res.statusText,
          body: { message: "User not authenticated " },
        };
      return res
        .json()
        .then((json) => ({
          status: res.status,
          statusText: res.statusText,
          body: json,
        }));
    })
    .then((output) => {
      if (output.status !== 200)
        throw {
          status: output.status,
          text:
            !output.body || !output.body.message
              ? output.statusText
              : output.body.message,
        };
      return output.json;
    });
}

function deletePost(postId) {
  console.log("API deletePost called, postId:", postId);
  return fetch(`${config.postCreator}/delete/${postId}`, {
    headers: {
      authorization: `Bearer ${Cookies.get("token")}`,
    },
    method: "DELETE",
    mode: "cors",
  })
    .then((res) => {
      console.log("Delete response status:", res.status);
      if (!res.ok) {
        return res.text().then((text) => {
          throw new Error(
            `HTTP error! status: ${res.status}, message: ${text}`
          );
        });
      }
      return res.text().then((text) => (text ? JSON.parse(text) : {}));
    })
    .then((data) => {
      console.log("Delete post response:", data);
      return { success: true, data };
    })
    .catch((error) => {
      console.error("Error in API deletePost:", error);
      return {
        success: false,
        error: error.message || "Post could not be deleted",
      };
    });
}

const postEditApi = {
  savePost,
  deletePost,
  addWebsiteToFilter: (website) => {
    return fetch(`${config.postCreator}/add-website-to-filter`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('token')}`,
      },
      body: JSON.stringify({ website }),
    })
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => { throw err; });
      }
      return response.json();
    });
  },

  removeWebsiteFromFilter: (website) => {
    return fetch(`${config.postCreator}/remove-website-from-filter`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('token')}`,
      },
      body: JSON.stringify({ website }),
    })
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => { throw err; });
      }
      return response.json();
    });
  },

  getWebsiteFilterList: () => {
    return fetch(`${config.postCreator}/get-website-filter-list`, {
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`,
      },
    })
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => { throw err; });
      }
      return response.json();
    });
  },
};

export default postEditApi;
