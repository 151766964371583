import React from "react";
import PropTypes from "prop-types";
import "./Button.css";
import classNames from "classnames";

const Button = ({
  title,
  className,
  icon,
  buttonPressed,
  disabled,
  active,
  overwriteDefaultStyle,
}) => (
  <button
    className={classNames(
      { button: !overwriteDefaultStyle },
      { active: active },
      className
    )}
    onClick={buttonPressed}
    disabled={disabled}
  >
    {icon}
    <span className="buttonText"> {title}</span>
  </button>
);

Button.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.object,
  buttonPressed: PropTypes.func,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
};

export default Button;
