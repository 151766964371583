import { all, call, put, takeEvery, select } from "redux-saga/effects";
import actions, { actionTypes } from "./actions";
import likeApi from "../../api/likeApi";
import likePostMutation from "./mutations/LikePost";
import unLikePostMutation from "./mutations/UnLikePost";
import newCommentMutation from "./mutations/NewComment";

import relayEnv from "../../relayEnv";

const loggedInUserSelector = (state) => state.auth.get("loggedInUser");

const decodeId = (id) => atob(id).split(":")[1];

function* likePost({ postId }) {
  yield put(actions.postLiked(postId));
  yield call(likePostMutation, relayEnv, postId);

  const loggedInUser = yield select(loggedInUserSelector);
  if (loggedInUser && loggedInUser.length > 0)
    yield call(likeApi.likePost, decodeId(postId));
}

function* unlikePost({ postId }) {
  yield put(actions.postUnLiked(postId));
  yield call(unLikePostMutation, relayEnv, postId);

  const loggedInUser = yield select(loggedInUserSelector);
  if (loggedInUser && loggedInUser.length > 0)
    yield call(likeApi.unlikePost, decodeId(postId));
}

function* postComment(params) {
  const loggedInUser = yield select(loggedInUserSelector);
  if (!loggedInUser || loggedInUser.length === 0) return;
  const { postId, postDate, comment } = params;
  if (!comment || comment.length === 0) return;
  try {
    yield call(
      newCommentMutation,
      relayEnv,
      { postId, postDate, comment },
      loggedInUser
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchLikes() {
  const likes = yield call(likeApi.getLikes);

  yield put(
    actions.likesFetched(likes.map((plainID) => btoa(`Post:${plainID}`)))
  );
}

export default function* rootSaga() {
  yield all([
    takeEvery(actionTypes.LIKE_POST, likePost),
    takeEvery(actionTypes.UNLIKE_POST, unlikePost),
    takeEvery(actionTypes.POST_COMMENT, postComment),
    takeEvery(actionTypes.FETCH_LIKES, fetchLikes),
  ]);
}
